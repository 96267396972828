'use strict';

module.exports = function (include) {
    if (typeof include === 'function') {
        include();
    } else if (typeof include === 'object') {
        Object.keys(include).forEach(function (key) {
            if (typeof include[key] === 'function') {
                include[key]();
            }
        });
    }
};

/**
     * @function
     * @description Generate svg icon
     * @param icon  {String} svg icon name
     * @returns {String} SVG tag
     */
module.exports.svg = function (icon, extraClasses) {
    return '<svg class="icon ' + icon + ' ' + extraClasses + ' svg-' + icon +'-dims"><use xlink:href="' + Urls.svg + '#' + icon + '"/></svg>';
}