'use strict';

module.exports = function () {
    var hasToolTip = $('.info-icon');
    hasToolTip.on('click', function () {
        $(this).find('.tooltip').toggleClass('d-none');
        if (!$(this).find('.tooltip').hasClass('d-none')) {
            $('<div class="tooltip-backdrop"></div>').appendTo('body');
        } else {
            $('.tooltip-backdrop').remove();
        }
    });

    $('body').on('click', '.tooltip-backdrop', function () {
        hasToolTip.find('.tooltip').addClass('d-none');
        $('.tooltip-backdrop').remove();
    });
};
