'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');
var scrollTriggerBTTDesktop = 400;
var scrollTriggerBTTMobile = 200;
var desktopBreakpoint = 961;
var positionFixedHeader = 0;

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

var backToTop = function () {
    var scrollTop = $(window).scrollTop();
    var desktopQuery = window.matchMedia('(min-width: 961px)').matches;
    var scrollTrigger;

    if (desktopQuery.matches) {
        scrollTrigger = scrollTriggerBTTDesktop;

    } else {
        scrollTrigger = scrollTriggerBTTMobile;
    }

    if (scrollTop > scrollTrigger) {
        $('.back-to-top').addClass('show');
    } else {
        $('.back-to-top').removeClass('show');
    }
    if (window.innerWidth < desktopBreakpoint) {
        $('.header-banner').css('display', 'none');
    }
};

module.exports = function () {
    $(document).ready(function () {
        try {
            const loggedInElement = $(".logged-in");
            if (loggedInElement.length > 0) {
                loggedInElement.parent().prepend(loggedInElement);
            }
            var targetElement = $(".minicart-link")[0];
            var observer = new MutationObserver(function (mutationsList, observer) {
                for (var mutation of mutationsList) {
                    if (mutation.type === 'childList' || mutation.type === 'characterData') {
                        if($(targetElement).find(".minicart-quantity").text() === '0') {
                            $(".minicart-quantity").addClass("text-white");
                        } else {
                            $(".minicart-quantity").removeClass("text-white");
                        }
                    }
                }
            });
            var config = { childList: true, subtree: true, characterData: true };
            observer.observe(targetElement, config);
        } catch (error) {
            console.log("An error occurred:", error.message);
        }
        const $searchInput = $(".search-for-fixed-header .search-field");
        const $clearIcon = $(".search-for-fixed-header .search-clear-icon");
        const $searchContainer = $(".search-for-fixed-header .site-search form");
        function adjustSearchField() {
            if ($(window).width() <= 768) {
            if ($searchInput.val().trim() !== "") {
                $clearIcon.show();
                $searchContainer.width("90%");
            } else {
                $clearIcon.hide();
                $searchContainer.width("100%");
            }
            } else {
            $clearIcon.hide();
            $searchContainer.width("100%");
            }
        }
        adjustSearchField();
        $searchInput.on("input", adjustSearchField);
        $(window).on("resize", adjustSearchField);
        $clearIcon.on("click", function () {
            $searchInput.val("");
            $clearIcon.hide();
            $searchContainer.width("100%");
        });
    });
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).parent().find('.svg-close').hide();
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                $('body').addClass("nav-model-opened");
                $('html').addClass('level-2-open');
                e.stopImmediatePropagation();
                e.preventDefault();
            } else {
                window.location.href = $(this).attr("href");
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
        $('body').removeClass("nav-model-opened");
        $('html').removeClass('level-2-open');
        $('body').removeClass('modal-open');
        $('.search-mobile').find('.site-search').removeClass('active');
        // $('.main-nav-container').append($(".search-mobile"));
        //$(".search-field").val("").focus();
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
        $('html').removeClass('level-2-open');
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
        $('body').removeClass("nav-model-opened");
        $('html').removeClass('level-2-open');
        $('.modal-background').hide();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');
        $('body').addClass("nav-model-opened");
        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
        // $(".nav-container-mob").prepend($(".search-mobile"));
        //$(".search-field").val("").focus();
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .signed-user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if(scroll > 400) {
            $(".main-nav-container").addClass('position-fixed');
            if($(window).width() > 991) {
                $(".header.container").addClass('d-none');
                $(".search-for-fixed-header").removeClass("d-sm-none");
                $(".stycky-header-minicart").removeClass('d-none');
                $(".sticky-header-brand-logo").removeClass('d-none');
            } else {
                $(".header.container").removeClass('d-none');
                $(".search-for-fixed-header").addClass("d-sm-none");
                $(".stycky-header-minicart").addClass('d-none');
            }
        } else {
            $(".main-nav-container").removeClass('position-fixed');
            $(".sticky-header-brand-logo").addClass('d-none');
            $(".header.container").removeClass('d-none');
            $(".search-for-fixed-header").addClass("d-sm-none");
            $(".stycky-header-minicart").addClass('d-none');
            $(".main-nav-container").trigger("click");
        }
        // if (scroll > positionFixedHeader) {
        //     $(".main-nav-container").removeClass('position-fixed');
        //     $(".header.container").removeClass('d-none');
        //     $(".search-for-fixed-header").addClass("d-sm-none");
        //     $(".stycky-header-minicart").addClass('d-none');
        // } else {
        //     $(".main-nav-container").addClass('position-fixed');
        //     if($(window).width() > 991) {
        //         $(".header.container").addClass('d-none');
        //         $(".search-for-fixed-header").removeClass("d-sm-none");
        //         $(".stycky-header-minicart").removeClass('d-none');
        //         if($("#sg-navbar-collapse").find(".navbar-header.brand").length == 0) {
        //             $("#sg-navbar-collapse").prepend($(".navbar-header.brand").clone());
        //         }
        //     } else {
        //         $(".header.container").removeClass('d-none');
        //         $(".search-for-fixed-header").addClass("d-sm-none");
        //         $(".stycky-header-minicart").addClass('d-none');
        //     }
        // }
        positionFixedHeader = scroll;
    });
    if ($('.back-to-top').length) {
        $(window).on('scroll', function () {
            backToTop();
        });

        $('body').on('click', '.back-to-top', function (e) {
            e.preventDefault();
            if (window.innerWidth < desktopBreakpoint) {
                $('.header-banner').css('display', 'none');
            }
            $('html,body').animate({
                scrollTop: 0
            }, 500);
        });
    }
    $('.custom-level-two').on('click', function () {
        $(this).toggleClass('active');
        $(this).closest('.custom-li-link').find('ul.level-3').toggleClass('hidden-md-down');
    });
};
