'use strict';

var base = require('base/components/clientSideValidation');

/**
* Validate whole form. Requires `this` to be set to form object
* @param {jQuery.event} event - Event to be canceled if form is invalid on blur.
* @returns {boolean} - Flag to indicate if form is valid on blur
*/
function validateFormBlur(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (!this.validity.valid) {
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var emptyFieldValidationMsg = window.Resources.EMPTY_FORM_ERROR_MSG;
                var validationMessage = emptyFieldValidationMsg;
                $(this).addClass('is-invalid');
                $(this).removeClass('has-success');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        }
    } else {
        $(this).removeClass('is-invalid');
        $(this).addClass('has-success');
        $(this).closest('div.form-group').find('.invalid-feedback').text('');
    }
    return valid;
}

module.exports = {
    invalid: base.invalid,

    submit: base.submit,

    buttonClick: base.buttonClick,

    functions: base.functions,

    focusoutValidation: function () {
        $('body').on('blur', 'input, select, textarea', function (e) {
            validateFormBlur.call(this, e);
        });
    },

    validateFormFill: function () {
        $('body').on('validateFormFill', function () {
            $('.form-group .invalid-feedback').text('');
            $('.form-group .is-invalid').removeClass('is-invalid');
            $('input, select').each(function () {
                if ($(this).val()) {
                    validateFormBlur.call(this);
                }
            });
        });
    },

    inputFieldEmptyCheck: function () {
        $('body').on('input change', 'input.shippingAddressOne', function () {
            if ($(this).val()) {
                if ($('.shippingAddressCity').val()) {
                    $('.shippingAddressCity').blur();
                }
                if ($('.shippingZipCode').val()) {
                    $('.shippingZipCode').blur();
                }
            }
        });
    }
};
